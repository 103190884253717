<template>
  <div class="components-content" v-html="content"></div>
</template>

<script>
import { fetchBenefitAbout } from "@/api/benefit.js";
export default {
  data() {
    return {
      content: ""
    };
  },
  mounted() {
    this.getAbout();
  },
  methods: {
    async getAbout() {
      const {
        result: { yi }
      } = await fetchBenefitAbout();
      this.content = yi;
    }
  }
};
</script>

<style lang="less" scoped></style>
