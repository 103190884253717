<template>
  <div class="benefit">
    <top-head></top-head>
    <menu-list :menuList="menuList" @changeActive="changeActive"></menu-list>
    <component :is="active.component"></component>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import TopHead from "@/components/TopHead";
import BottomBar from "@/components/BottomBar";
import MenuList from "@/components/MenuList";
import BenefitAbout from "@/views/benefit/components/BenefitAbout";
import BenefitDynamic from "@/views/benefit/components/BenefitDynamic";
import BenefitPromotion from "@/views/benefit/components/BenefitPromotion";
import BrandProject from "@/views/benefit/components/BrandProject";
export default {
  name: "Benefit",
  data() {
    return {
      menuList: [
        {
          title: "基金简介",
          component: "BenefitAbout"
        },
        {
          title: "品牌项目",
          component: "BrandProject"
        },
        {
          title: "基金动态",
          component: "BenefitDynamic"
        },
        {
          title: "公益推介",
          component: "BenefitPromotion"
        }
      ],
      active: {}
    };
  },
  methods: {
    changeActive(active) {
      this.active = active;
      this.$router.push({
        name: "Benefit",
        params: {
          component: active.component
        }
      });
    }
  },
  components: {
    BenefitAbout,
    BenefitDynamic,
    BenefitPromotion,
    BrandProject,
    TopHead,
    BottomBar,
    MenuList
  }
};
</script>

<style lang="less" scoped></style>
