var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"components-content"},[_c('div',{staticClass:"placed-the-top-project",on:{"click":function($event){return _vm.$router.push({
        name: 'BrandProjectDetails',
        params: {
          id: _vm.topData.id,
          pid: 24
        }
      })}}},[_c('div',{staticClass:"placed-the-top-project-cover"},[_c('img',{attrs:{"src":_vm.topData.head_pic,"alt":""}})]),_c('div',{staticClass:"placed-the-top-project-title"},[_vm._v(" "+_vm._s(_vm.topData.title)+" ")]),(_vm.topData.content)?_c('div',{staticClass:"placed-the-top-project-intro",domProps:{"innerHTML":_vm._s(_vm.topData.content.replace(/<[^>]+>/g, ''))}}):_vm._e(),_c('div',{staticClass:"placed-the-top-project-time-bar frcb"},[_c('div',{staticClass:"placed-the-top-project-time"},[_vm._v(" "+_vm._s(_vm.topData.create_time)+" ")]),_vm._m(0)])]),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticClass:"brand-project-list"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"brand-project-item",on:{"click":function($event){return _vm.$router.push({
            name: 'BrandProjectDetails',
            params: {
              id: item.id,
              pid: 24
            }
          })}}},[_c('div',{staticClass:"brand-project-item-cover"},[_c('img',{attrs:{"src":item.head_pic,"alt":""}})]),_c('div',{staticClass:"brand-project-item-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"brand-project-item-time"},[_vm._v(" "+_vm._s(item.create_time)+" ")]),_c('div',{staticClass:"brand-project-item-intro",domProps:{"innerHTML":_vm._s(item.content.replace(/<[^>]+>/g, ''))}}),_c('div',{staticClass:"placed-the-top-project-more frc"},[_c('div',{staticClass:"more"},[_vm._v("更多")]),_c('img',{staticClass:"more-iocn",attrs:{"src":require("@/assets/images/news_right_arrow.png"),"alt":""}})])])}),0)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"placed-the-top-project-more frc"},[_c('div',{staticClass:"more"},[_vm._v("更多")]),_c('img',{staticClass:"more-iocn",attrs:{"src":require("@/assets/images/news_right_arrow.png"),"alt":""}})])}]

export { render, staticRenderFns }