import { render, staticRenderFns } from "./BenefitPromotion.vue?vue&type=template&id=fdd90144&scoped=true&"
import script from "./BenefitPromotion.vue?vue&type=script&lang=js&"
export * from "./BenefitPromotion.vue?vue&type=script&lang=js&"
import style0 from "./BenefitPromotion.vue?vue&type=style&index=0&id=fdd90144&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdd90144",
  null
  
)

export default component.exports